.banner {
  width: 100%;
  height: 800px; /* Ajusta la altura para pantallas grandes */
  position: relative;
  overflow: hidden;
}

.banner-link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: transform 1s ease-in-out;
  z-index: 0;
  visibility: hidden; /* Oculta los enlaces inactivos */
}

.banner-link.active {
  visibility: visible; /* Muestra el enlace activo */
  z-index: 1; /* Coloca el enlace activo al frente */
}

.banner-image {
  width: 100%;
  height: 100%;
  object-fit: contain; /* Ajusta la imagen sin recortarla */
  position: absolute;
  left: 0;
  top: 0;
  transition: transform 1s ease-in-out;
}

/* Media queries para ajustar el tamaño del banner en diferentes pantallas */
@media (max-width: 1440px) {
  .banner {
    height: 500px;
  }
}

@media (max-width: 1024px) {
  .banner {
    height: 400px;
  }
}

@media (max-width: 768px) {
  .banner {
    height: 300px;
  }
}

@media (max-width: 480px) {
  .banner {
    height: 200px;
  }
}
