.sections-container {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  
}

.section-titles {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-bottom: 20px;

  @media (max-width: 920px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.section-title {
  font-size: 24px;
  cursor: pointer;
  text-align: center;
  background-color: #00A859;
  padding: 10px;
  border-radius: 4px;
  transition: background-color 0.3s ease;

  @media (max-width: 920px) {
    margin-bottom: 20px;
    
  }
}

.section-title:hover {
  background-color: #007A44;
}

.section-contentParteCarrousel {
  font-size: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease;
  max-width: 800px;
  margin-top: 10px;
  margin-bottom: 15px;

  p{
    margin-left: 15px;
    text-align: justify;
    margin-right: 15px;
}


.btn-whatsapp {
  background-color: #00A859; 
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.btn-whatsapp:hover {
  background-color: #5f1089; 
}

.btn-whatsapp:focus {
  outline: none;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Añade una sombra al hacer clic */
}


  
  &.expanded {
    max-height: 1100px; // Ajusta según el contenido
  }

  @media (max-width: 920px) {
    display: flex;
    flex-direction: column;
    text-align: center;
    

    .a-presupuesto{
      display: flex;
      flex-direction: column;
      text-align: center;
      justify-content: center;
      text-decoration: none;
      margin-bottom: 20px;
      margin-left: 15px;
    }

  }

  .content-left {
    flex: 1;
    text-align: justify;
    margin-right: 15px;
    
  }

  .content-right {
    flex: 1;
    display: flex;
    justify-content: center;

    .carousel-image {
      width: 100%;
      height: auto;
      object-fit: cover;
      border-radius: 8px;
      max-width: 400px; // Ajuste del tamaño máximo de la imagen en el carrusel
    }
  }
}

.a-presupuesto {
  display: block;
  margin-top: 20px;
}

.btn-whatsapp {
  background-color: #25D366;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}

.btn-whatsapp:hover {
  background-color: #1EB844;
}
