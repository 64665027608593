.presupuesto-container {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    color: black; // Cambia el color según lo necesites
  }
  
  .section-titles {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    margin-bottom: 20px;
    color: white;
  
    @media (max-width: 920px) {
      flex-direction: column;
      align-items: center;
    }
  }
  
  .section-title {
    font-size: 24px;
    cursor: pointer;
    text-align: center;
    background-color: #00A859;
    padding: 10px;
    border-radius: 4px;
    transition: background-color 0.3s ease;

   
  
    @media (max-width: 920px) {
      margin-bottom: 20px;
    }
  }

  .section-title:hover {
    background-color: #5f1089; 
  }
  
  .section-content {
    font-size: 20px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 15px;
    max-width: 600px;
    text-align: justify;

    p{
        margin-left: 15px;
        text-align: justify;
        margin-right: 15px;
    }

    .a-presupuesto{
      text-decoration: none;
    }

    .text-presupuesto{     
        margin-left: 0px;
        text-align: justify;
    }
  
    ul {
      margin-top: 15px;
      margin-left: 30px;
      margin-right: 5px;
      list-style-type: disc; // Asegúrate de que las viñetas estén activadas
      text-align: justify;

      li {
        margin-top: 15px; // Espaciado de 15px entre los puntos
        text-align: justify;
        margin-right: 15px;
      }
    }
  
    &.expanded {
      max-height: 1100px; // Ajusta según el contenido
    }
  }
  
  .button {
    background-color: #00A859;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    text-decoration: none;
    cursor: pointer;
    margin-top: 20px;
  
    &:hover {
      background-color: #008e45; // Color en hover
    }
  }
  