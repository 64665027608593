
.container-publicidad {
    background-color: #5f1089;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;

    a{
        text-decoration: none; /* Elimina cualquier subrayado */
        color: inherit; /* Mantiene el color del texto como está */
    }

    h1{
        color: white;
        font-size: 26px;
        text-align: center;
        margin-right: 15px;
    }

    .social-iconWspPubli{
        color: #25D366; // Color de WhatsApp
        position: relative;
        top: 4px;
        
    }
  }

  @media (max-width: 920px) {
    .container-publicidad{
        h1{
            font-size: 18px;
        }
    }
  }

  @media (max-width: 640px) {
    .container-publicidad{
       
        h1{
            font-size: 16px;
            max-width: 300px;
        }
    }
  }
  
  