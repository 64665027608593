.image-container {
    width: 100%;
    height: 100%; /* Opcional: puedes ajustar la altura según lo que necesites */
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  
  .responsive-image {
    width: 100%;
    height: auto;
    max-width: 100%; /* Asegura que la imagen no se desborde */
    display: block;
    object-fit: cover; /* Ajusta la imagen para que cubra el contenedor */
  }
  